.App {
  text-align: center;
  justify-content: center;
  color: white;

}

.App-logo {
  pointer-events: none;
  width: 200px;
  margin: 0 auto;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  min-height: 105px;
  display: flex;

  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.pol h1,
.pol p {
  color: white;
}

.pol p {
  font-size: 24px;
  font-style: italic;
}

.pol h1 {
  margin-top: 50px;
}


.pol {
  margin-top: 50px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.header {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 2vh;
}

.disclaimer {
  border: 2px solid red;
  height: 100%;
  margin-top: 5vh;
  color: white;
  margin: 0 auto;
  padding: 25px;
  margin-bottom: 25px;
  background-color: rgba(195, 16, 12, 0.75);
}

.disclaimer span {
  font-size: 24px;
  font-weight: bold;
}


.info p,
.info li {
  font-size: 20px;
}

.tagline {
  width: 25%;
  height: 100%;
  margin-top: 5vh;
  color: white;
  margin: 0 auto;
  padding: 25px;
  margin-bottom: 25px;
  font-weight: bold;
  border: 2px solid #C54087;
  background-color: rgba(0, 0, 0, 0.5);
}

.tagline span {
  font-size: 24px;
}

.info {
  height: 100%;
  margin-top: 5vh;
  color: black;
  margin: 0 auto;
  padding: 25px;
  margin-bottom: 25px;
  background-color: rgba(255, 255, 255, 0.95);
  border: 2px solid white;
}
.info span {
  color: #00091E;
  font-size: 30px;
}

@media (min-width: 972px) {

  .info,
  .pol,
  .disclaimer {
    width: 65%;
  }

  .example {
    border-radius: 10px;
    padding: 1em;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    width: 45%;
    margin: 0 auto;
    margin-top: 25px;

  }

  .column {
    width: 50%;
    padding: 10px;
  }

  .container {
    display: flex;
  }
}

@media (max-width: 971px) {

  .info,
  .pol,
  .disclaimer {
    width: 85%;
  }

  .example {
    border-radius: 10px;
    padding: 1em;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    width: 95%;
    margin: 0 auto;
    margin-top: 25px;

  }

  .column {
    width: 95%;
    padding: 10px;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
  }
}

.text-column {
  background-color: none;
}

.table-column {
  background-color: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 25px;
}

table,
th,
td {
  border: 1px solid black;
}

th,
td {
  padding: 10px;
  text-align: left;
}

#sac {
  background-color: #007bff;
  /* Blue background */
  border: none;
  /* Remove borders */
  color: white;
  /* White text */
  padding: 15px 32px;
  /* Some padding */
  text-align: center;
  /* Centered text */
  text-decoration: none;
  /* Remove underline */
  display: inline-block;
  font-size: 24px;
  margin: 4px 2px;
  cursor: pointer;
  /* Mouse pointer on hover */
  border-radius: 12px;
  /* Rounded corners */
  transition-duration: 0.4s;
  /* Transition effects on hover */
  margin-bottom: 25px;
}

button:hover {
  background-color: #0056b3;
  /* Darker blue on hover */
  color: white;
}

.copy-input-container {
  align-items: center;
  display: -webkit-flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
}

.copy-input {
  padding: 5px;
  border: 1px solid #ccc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 24px;
  background: transparent;
  color: black;
  padding: 15px;
  width: 100%;
}

.copy-button {
  padding: 15px 15px;
  border: 1px solid #ccc;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: white;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
}

.copy-button:hover {
  background-color: #C6C6C6;
}


a {
  color: black;
}

.examples {
  display: flex;
  flex-wrap: wrap;
}

.example h2 {
  margin-bottom: 0.5em;
  color: #0D2968;
}

.example p {
  margin-top: 0.5em;
}

.accepted-table {
  overflow: scroll;
}

ul li {
  margin-top: 10px;
}

.social {
  margin: 0 auto;
}

/* Footer */
.footer {
  color: black;
  padding: 20px;
  margin-top: 15px;
}

.footer-container {
  margin: 0 auto;
}

.social-icons a {
  color: black;
  font-size: 40px;
  margin-left: 20px;
  margin-right: 20px;
}

.social-icons a:hover {
  color: #aaaaaa;
}

.social-icons a:last-child {
  margin-right: 0;
}

.social-icons a:focus {
  outline: none;
}

.social-icons svg {
  vertical-align: middle;
}

.footer-copyright {
  background-color: black;
  color: white;
  padding: 15px;
  opacity: 75%;
}
.vertical-center {
    display: flex;
    align-items: center;      /* Vertical center */
}